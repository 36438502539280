import axios from "axios";

const BASE_API = process.env.REACT_APP_BASE_API;

class FooterService {
  getFooterOne = (data) => {
    return axios.get(`${BASE_API}/menu_items/header-menu`);
  };

  getFooterTwo = (data) => {
    return axios.get(`${BASE_API}/menu_items/footer-menu`);
  };

  getSocialMedia = (data) => {
    return axios.get(`${BASE_API}/menu_items/social-media`);
  };

  getContactDetails = (data) => {
    return axios.get(`${BASE_API}/menu_items/contact-information-menu`);
  };

  getPrivacyPolicy = (data) => {
    return axios.get(`${BASE_API}/node/page`, { params: data });
  };

  getTermsAndCondition = (data) => {
    return axios.get(`${BASE_API}/node/page`, { params: data });
  };
  
}

export default new FooterService();
