import axios from "axios";
import { getCsrfToken } from "helpers/Storage";

const BASE_API = process.env.REACT_APP_BASE_API;
const APP_DOMAIN_URL = process.env.REACT_APP_DOMAIN_URL;

const headers = {
  "X-CSRF-Token": getCsrfToken(),
};

class DashBoardService {
  getSubscriptionVideoList = (data) => {
    return axios.post(
      `${APP_DOMAIN_URL}/api/smart-born/subscription-video/listing?_format=json`,
       data,
      { headers }
    );
  };

  getVideo = (data) => {
    return axios.post(
      `${APP_DOMAIN_URL}/api/smart-born/subscription-video/detail?_format=json`,
       data,
      { headers }
    );
  };

  getChildList = (data) => {
    return axios.post(
      `${APP_DOMAIN_URL}/api/smart-born/child/detail?_format=json`,
       data,
      { headers }
    );
  };


  getParentChildDetails = (data) => {
    return axios.get(`${BASE_API}/api/dashboard`, { params: data });
  };

  getActivities = (data) => {
    return axios.get(`${BASE_API}/api/activities`, { params: data });
  };

  getActivitiesDetails = (data) => {
    return axios.get(`${BASE_API}/api/activities`, { params: data });
  };

  getDidYouKnowDetails = (data) => {
    return axios.get(`${BASE_API}/api/did-you-know`, { params: data });
  };
}

export default new DashBoardService();
