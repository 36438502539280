/**
 *************************************************************************************************************************************
 * Project API Constants
 *************************************************************************************************************************************
 */

module.exports = {
  START: "/",

  FORGOT_PASSWORD: "forgot-password",
  LOGIN: 'login',
  REGISTER: "register",
  THANK_YOU: "thank-you",

  LOGIN_UPDATE: '/login',

  HOME: "/",
  ABOUT_US: "about-us",
  WHY_BORN_MART: "why-born-smart",
  PARENTING_TIPS: "parenting-tips",
  BRAIN_DEVELOPMENT: "brain-development",
  PARENTING_TIPS_DETAILS : "parenting-tips-details",
  CONTACT_US : "contact-us",
  FAQ : "faq",
  CONNECT_WITH_US : "connect-with-us",
  OUR_EXPERTS : "our-experts",


  DASHBOARD : '/app/dashboard',
  PROFILE: '/app/profile',
  QUESTIONS: '/app/questions',
  HISTORY: '/app/history',
  SUBSCRIPTION: '/app/subscription',
  HELP: '/app/help',
  RENEWMEMBERSHIP: '/app/renew-plan',
  VIDEOHISTORY: '/app/video-history',

};