
export const PROFILE = {

    id: 1,
    parentFirstName: "Vinod",
    parentLastName: "Sharma",
    country: { name: 'USA', code: '+1' },
    mobileNo: 4155552671,
    email: "vinod.sharma@gmail.com",
    childs: [
        {
            id: 1,
            childFirstName: "Rahul",
            childLastName: "Sharma",
            childAge: "18 Months",
            childDateOfBirth: "13-03-2020",
            childGender: "Boy",
            img:"/assets/img/dashboard/gender-boy.svg",
        },
        {
            id: 2,
            childFirstName: "Diksha",
            childLastName: "Sharma",
            childAge: "3 Months",
            childDateOfBirth: "15-06-2021",
            childGender: "Girl",
            img:"/assets/img/dashboard/Gender_Girl.svg",
        },
    ]
}

export const CHILD_ONE =
{
    id: 1,
    childPhoto: { preview: "", raw: "" },
    childFirstName: "Rahul",
    childLastName: "Sharma",
    childAge: "18 Months",
    childDateOfBirth: "13-03-2020",
    childGender: "Boy",
}

export const CHILD_TWO =
{
    id: 2,
    childPhoto: { preview: "", raw: "" },
    childFirstName: "Diksha",
    childLastName: "Sharma",
    childAge: "3 Months",
    childDateOfBirth: "15-06-2021",
    childGender: "Girl",
}

