export function DeepTrimAndCleanUp({ data, removeEmptyStringKeyFlag }) {
  for (var prop in data) {
    var value = data[prop], type = typeof value;
    if (value !== null && (type === "string" || type === "object" || type === "number") && data.hasOwnProperty(prop)) {
      if (type === "object") {
        DeepTrimAndCleanUp({ data: data[prop], removeEmptyStringKeyFlag: removeEmptyStringKeyFlag || false, });
        if (Object.entries(data[prop]).length === 0) {
          delete data[prop];
        }
        if (data[prop] && data[prop].length) {
          data[prop] = data[prop].filter((e) => e);
        }
      } else if (type === "string") {
        data[prop] = data[prop]?.trim();
        if ((removeEmptyStringKeyFlag || false) && (data[prop] === "" || data[prop] === undefined)) {
          delete data[prop];
        }
      }
    } else if (value === null) {
      delete data[prop];
    }
  }
};
