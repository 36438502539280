import AuthAPI from "./auth";
import TopnavAPI from "./topnav";
import HomeAPI from "./home";
import FooterAPI from "./footer";
import RegisterAPI from "./register";
import ProfileAPI from "./profile";
import DashboardAPI from "./dashboard";
import ParentingTipsAPI from "./parentingTips";
import OurExpertsAPI from "./our-experts";
import AboutUsAPI from "./about-us";
import ContactUsAPI from "./contact-us";
import SubscriptionAPI from "./subscription";
import VideoHistoryAPI from "./video-history";
import OfferingAPI from "./offering";
import AdvantageAPI from "./advantage";
import FaqAPI from './faq';

const exportedObject = {
  AuthAPI,
  HomeAPI,
  TopnavAPI,
  FooterAPI,
  RegisterAPI,
  ProfileAPI,
  DashboardAPI,
  ParentingTipsAPI,
  OurExpertsAPI,
  AboutUsAPI,
  ContactUsAPI,
  SubscriptionAPI,
  VideoHistoryAPI,
  OfferingAPI,
  AdvantageAPI,
  FaqAPI,
};

export default exportedObject;
