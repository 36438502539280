import { SET_FIRST_NAME } from "./actionTypes";

const INITIAL_STATE = {
    firstName:  localStorage.getItem('first_name')
};

export default (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case SET_FIRST_NAME:
            localStorage.setItem("first_name", action.payload)
            return {...state, firstName: action.payload};
        default:
            return state;
    }
};
