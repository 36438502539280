
export const PARENT_CHILD_DETAILS = {
    id: 1,
    name: "Vinod",
    childs: [
        {
            id: 1,
            name: "Rahul",
            icon: "",
            age: 16,
            months: [
                1,
                2,
                3
            ],
            noOfVideos: 5,
            detail: "Lorem Ipsum is simply dummy",
            img:"/assets/img/dashboard/gender-boy.svg",
        },
        {
            id: 2,
            name: "Diksha",
            icon: "",
            age: 3,
            months: [
                1,
                2,
                3
            ],
            noOfVideos: 3,
            detail: "Lorem Ipsum is simply dummy",
            img:"/assets/img/dashboard/Gender_Girl.svg",
        }
    ]
}

export const ACTIVITIES = [
    {
        month: 1,
        title: "Roll, roll the ball",
        skill: "Sensory Skills",
        icon: "/assets/img/dashboard/puzzle.svg",
        purpose: "To reinforce your baby compreshension of cause and effect",
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
        videoUrl: "https://player.vimeo.com/video/253989945",
        thumbnail: "/assets/img/dashboard/video-img.png"
    },
    {
        month: 1,
        title: "Roll, roll the ball",
        skill: "Motor Skills",
        icon: "/assets/img/dashboard/crawling-baby-icon.svg",
        purpose: "To reinforce your baby compreshension of cause and effect",
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
        videoUrl: "https://player.vimeo.com/video/252443587",
        thumbnail: "/assets/img/dashboard/video-img.png"
    },
    {
        month: 1,
        title: "Getting to know your food",
        skill: "Daily Activities",
        icon: "/assets/img/dashboard/puzzle1.svg",
        purpose: "To reinforce your baby compreshension of cause and effect",
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
        videoUrl: "https://player.vimeo.com/video/8697260",
        thumbnail: "/assets/img/dashboard/video-img.png"
    },
    {
        month: 1,
        title: "Rattle fun",
        skill: "Daily Activities",
        icon: "/assets/img/dashboard/puzzle1.svg",
        purpose: "To reinforce your baby compreshension of cause and effect",
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
        videoUrl: "https://player.vimeo.com/video/253989945",
        thumbnail: "/assets/img/dashboard/video-img.png"
    },
    {
        month: 2,
        title: "Roll, roll the ball",
        skill: "Sensory Skills",
        icon: "",
        purpose: "To reinforce your baby compreshension of cause and effect",
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
        videoUrl: "https://player.vimeo.com/video/253989945",
        thumbnail: ""
    },
    {
        month: 2,
        title: "Roll, roll the ball",
        skill: "Motor Skills",
        icon: "",
        purpose: "To reinforce your baby compreshension of cause and effect",
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
        videoUrl: "https://player.vimeo.com/video/253989945",
        thumbnail: ""
    },
    {
        month: 2,
        title: "Getting to know your food",
        skill: "Daily Activities",
        icon: "",
        purpose: "To reinforce your baby compreshension of cause and effect",
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
        videoUrl: "https://player.vimeo.com/video/253989945",
        thumbnail: ""
    },
    {
        month: 2,
        title: "Rattle fun",
        skill: "Daily Activities",
        icon: "",
        purpose: "To reinforce your baby compreshension of cause and effect",
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
        videoUrl: "https://player.vimeo.com/video/253989945",
        thumbnail: ""
    },
    {
        month: 3,
        title: "Roll, roll the ball",
        skill: "Sensory Skills",
        icon: "",
        purpose: "To reinforce your baby compreshension of cause and effect",
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
        videoUrl: "https://player.vimeo.com/video/253989945",
        thumbnail: ""
    },
    {
        month: 3,
        title: "Roll, roll the ball",
        skill: "Motor Skills",
        icon: "",
        purpose: "To reinforce your baby compreshension of cause and effect",
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
        videoUrl: "https://player.vimeo.com/video/253989945",
        thumbnail: ""
    },
    {
        month: 3,
        title: "Getting to know your food",
        skill: "Daily Activities",
        icon: "",
        purpose: "To reinforce your baby compreshension of cause and effect",
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
        videoUrl: "https://player.vimeo.com/video/253989945",
        thumbnail: ""
    },
    {
        month: 3,
        title: "Rattle fun",
        skill: "Daily Activities",
        icon: "",
        purpose: "To reinforce your baby compreshension of cause and effect",
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
        videoUrl: "https://player.vimeo.com/video/253989945",
        thumbnail: ""
    }
]

export const DID_YOU_KNOW = {
    header: "Babies are born with 300 bones.",
    content: "That's 94 adults. Where do the extra bones go? They fuse together during development.",
    imageUrl: "/assets/img/dashboard/baby-bone.jpg"
}

export const SUBSCRIPTION_DATA = {
    payment_date:"Jun 24, 2021",
    child_subscription : [
       {
           id:1,
           name:"Rahul",
           subscription_type:"Paid",
           months:"12 Months",
           eligible:"13-18 & 19-24 Months",
           days_left:"4",
           price:"$54",
           charged_date:"Nov 24,2021"
       },
       {
        id:2,
        name:"Diksha",
        subscription_type:"Free",
        months:"6 Months",
        eligible:"1-6 Months",
        days_left:"",
        price:"$20",
        charged_date:"Feb 08,2022"
       }
    ]
}

export const VIDEO_HISTORY = {
    child_data : [
       {
           id:1,
           name:"Rahul",
           subscription_type:"FREE",
           viewed:"36",
           total_video:"40",
           remaining:"4",
       },
       {
        id:2,
        name:"Diksha",
        subscription_type:"PAID",
        viewed:"60",
        total_video:"60",
        remaining:"0",
       }
    ]
}

export const CHILD_VIDEO_HISTORY = {
    id:1,
    name:"Rahul",
    subscription_type:"FREE",
    viewed:"36",
    total_video:"40",
    remaining:"4",
    video_list : [
        {
            video_thumbnail:"/assets/img/dashboard/video-img.png",
            viedo_name:"Roll, roll the ball",
            date:"Dec 2021",
            video_review:"Viewed",
        },
        {
            video_thumbnail:"/assets/img/dashboard/video-img.png",
            viedo_name:"You are my baby",
            date:"Dec 2021",
            video_review:"Viewed",
        },
        {
            video_thumbnail:"/assets/img/dashboard/video-img.png",
            viedo_name:"Rock-A-bye baby",
            date:"Nov 2021",
            video_review:"Viewed",
        },
        {
            video_thumbnail:"/assets/img/dashboard/video-img.png",
            viedo_name:"My Little baby",
            date:"Nov 2021",
            video_review:"Remaining",
        },
        {
            video_thumbnail:"/assets/img/dashboard/video-img.png",
            viedo_name:"Scarf Peek-a-boo",
            date:"Nov 2021",
            video_review:"Remaining",
        },
    ]
}