import { SET_DUE_DATE,SET_SUBSCRIPTION,SET_SUBSCRIPTION_CONDITION } from "./actionTypes";

const INITIAL_STATE = {
    dueDate: localStorage.getItem('subscription_end_month'),
    subscription: localStorage.getItem('subscription'),
    subscriptionCondition: localStorage.getItem('subscription_condition')
};

export default (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case SET_DUE_DATE:
            localStorage.setItem("subscription_end_month", action.payload)
            return {...state, dueDate: action.payload};
        case SET_SUBSCRIPTION:
            localStorage.setItem("subscription", action.payload)
            return {...state, subscription: action.payload};
        case SET_SUBSCRIPTION_CONDITION:
            localStorage.setItem("subscription_condition", action.payload)
            return {...state, subscriptionCondition: action.payload};
        default:
            return state;
    }
};
