import HttpStatus from "../../constants/HttpStatus";
import { PROFILE, CHILD_ONE, CHILD_TWO } from "../../data/profile";
import { DeepTrimAndCleanUp } from "../../helpers/DeepTrim";
import API from "./service";

export const initialState = {
    locale: "en"
};

const getProfileUser = async (data = initialState) => {
    try {
        DeepTrimAndCleanUp({ data, removeEmptyStringKeyFlag: true });
        const response = await API.getProfileUser(data)
            .then((res) => res)
            .catch((error) => {
                console.log("error", error);
                return (
                    error?.response || {
                        status: HttpStatus.NETWORK_CONNECT_TIMEOUT_ERROR,
                    }
                );
            });
        if (HttpStatus.SUCCESS.includes(response.status)) {
            data = { ...data, ...response.data, status: true };
        } else {
            data = { data: PROFILE, status: false };
        }
    } catch (error) {
        console.error("Exception occur in profile.getProfileUser ", error);
        data = { data: PROFILE, status: false };
    }
    return data;
};

const updateParent = async (data = initialState) => {
    try {
        DeepTrimAndCleanUp({ data, removeEmptyStringKeyFlag: true });
        const response = await API.updateParent(data)
            .then((res) => res)
            .catch((error) => {
                console.log("error", error);
                return (
                    error?.response || {
                        status: HttpStatus.NETWORK_CONNECT_TIMEOUT_ERROR,
                    }
                );
            });
        if (HttpStatus.SUCCESS.includes(response.status)) {
            data = { ...data, ...response.data, status: true };
        } else {
            data = { data: PROFILE, status: false };
        }
    } catch (error) {
        console.error("Exception occur in profile.updateParent ", error);
        data = { data: PROFILE, status: false };
    }
    return data;
};

const updatePassword = async (data = initialState) => {
    try {
        DeepTrimAndCleanUp({ data, removeEmptyStringKeyFlag: true });
        const response = await API.updatePassword(data)
            .then((res) => res)
            .catch((error) => {
                console.log("error", error);
                return (
                    error?.response || {
                        status: HttpStatus.NETWORK_CONNECT_TIMEOUT_ERROR,
                    }
                );
            });
        if (HttpStatus.SUCCESS.includes(response.status)) {
            data = { ...data, ...response.data, status: true };
        } else {
            data = { data: PROFILE, status: false };
        }
    } catch (error) {
        console.error("Exception occur in profile.updatePassword ", error);
        data = { data: PROFILE, status: false };
    }
    return data;
};


const updateChild = async (data = initialState) => {
    try {
        DeepTrimAndCleanUp({ data, removeEmptyStringKeyFlag: true });
        const response = await API.updateChild(data)
            .then((res) => res)
            .catch((error) => {
                console.log("error", error);
                return (
                    error?.response || {
                        status: HttpStatus.NETWORK_CONNECT_TIMEOUT_ERROR,
                    }
                );
            });
        if (HttpStatus.SUCCESS.includes(response.status)) {
            data = { ...data, ...response.data, status: true };
        } else {
            data = { data: PROFILE, status: false };
        }
    } catch (error) {
        console.error("Exception occur in profile.updateChild ", error);
        data = { data: PROFILE, status: false };
    }
    return data;
};

const getProfile = async (data = initialState) => {
    try {
        DeepTrimAndCleanUp({ data, removeEmptyStringKeyFlag: true });
        const response = await API.getProfile(data)
            .then((res) => res)
            .catch((error) => {
                console.log("error", error);
                return (
                    error?.response || {
                        status: HttpStatus.NETWORK_CONNECT_TIMEOUT_ERROR,
                    }
                );
            });
        if (HttpStatus.SUCCESS.includes(response.status)) {
            data = { ...data, ...response.data, status: true };
        } else {
            data = { data: PROFILE, status: false };
        }
    } catch (error) {
        console.error("Exception occur in profile.getProfile ", error);
        data = { data: PROFILE, status: false };
    }
    return data;
};

const getChildDetails = async (data = initialState) => {
    try {
        DeepTrimAndCleanUp({ data, removeEmptyStringKeyFlag: true });
        const response = await API.getChildDetails(data)
            .then((res) => res)
            .catch((error) => {
                console.log("error", error);
                return (
                    error?.response || {
                        status: HttpStatus.NETWORK_CONNECT_TIMEOUT_ERROR,
                    }
                );
            });
        if (HttpStatus.SUCCESS.includes(response.status)) {
            data = { ...data, ...response.data, status: true };
        } else {
            if (data.id === 1) {
                data = { data: CHILD_ONE, status: false };
            }
            else {
                data = { data: CHILD_TWO, status: false };
            }

        }
    } catch (error) {
        console.error("Exception occur in profile.getChildDetails ", error);
        if (data.id === 1) {
            data = { data: CHILD_ONE, status: false };
        }
        else {
            data = { data: CHILD_TWO, status: false };
        }
    }
    return data;
};

const updateProfile = async (data) => {
    try {
        DeepTrimAndCleanUp({ data, removeEmptyStringKeyFlag: true });
        const response = await API.updateProfile(data)
            .then((res) => res)
            .catch((error) => {
                console.log("error", error);
                return (
                    error?.response || {
                        status: HttpStatus.NETWORK_CONNECT_TIMEOUT_ERROR,
                    }
                );
            });
        if (HttpStatus.SUCCESS.includes(response.status)) {
            data = { ...data, ...response.data, status: true };
        } else {
            data = { ...data, status: false };
        }
    } catch (error) {
        console.error("Exception occur in profile.updateProfile ", error);
        data = { data: [], status: false };
    }
    return data;
};

const updateChildDetails = async (data) => {
    try {
        DeepTrimAndCleanUp({ data, removeEmptyStringKeyFlag: true });
        const response = await API.updateChildDetails(data)
            .then((res) => res)
            .catch((error) => {
                console.log("error", error);
                return (
                    error?.response || {
                        status: HttpStatus.NETWORK_CONNECT_TIMEOUT_ERROR,
                    }
                );
            });
        if (HttpStatus.SUCCESS.includes(response.status)) {
            data = { ...data, ...response.data, status: true };
        } else {
            data = { ...data, status: false };
        }
    } catch (error) {
        console.error("Exception occur in profile.updateChildDetails ", error);
        data = { data: [], status: false };
    }
    return data;
};

// const updatePassword = async (data) => {
//     try {
//         DeepTrimAndCleanUp({ data, removeEmptyStringKeyFlag: true });
//         const response = await API.updatePassword(data)
//             .then((res) => res)
//             .catch((error) => {
//                 console.log("error", error);
//                 return (
//                     error?.response || {
//                         status: HttpStatus.NETWORK_CONNECT_TIMEOUT_ERROR,
//                     }
//                 );
//             });
//         if (HttpStatus.SUCCESS.includes(response.status)) {
//             data = { ...data, ...response.data, status: true };
//         } else {
//             data = { ...data, status: false };
//         }
//     } catch (error) {
//         console.error("Exception occur in profile.updatePassword ", error);
//         data = { data: [], status: false };
//     }
//     return data;
// };

const deleteChild = async (data) => {
    try {
        DeepTrimAndCleanUp({ data, removeEmptyStringKeyFlag: true });
        const response = await API.deleteChild(data)
            .then((res) => res)
            .catch((error) => {
                console.log("error", error);
                return (
                    error?.response || {
                        status: HttpStatus.NETWORK_CONNECT_TIMEOUT_ERROR,
                    }
                );
            });
        if (HttpStatus.SUCCESS.includes(response.status)) {
            data = { ...data, ...response.data, status: true };
        } else {
            data = { ...data, status: false };
        }
    } catch (error) {
        console.error("Exception occur in profile.deleteChild ", error);
        data = { data: [], status: false };
    }
    return data;
};

const exportedObject = {
    getProfileUser,
    updateParent,
    updatePassword,
    updateChild,
    getProfile,
    getChildDetails,
    updateProfile,
    updateChildDetails,
    deleteChild,
};

export default exportedObject;