import axios from "axios";

const BASE_API = process.env.REACT_APP_BASE_API;

class TopnavService {

    getTopNavs = (data) => {
        return axios.get(`${BASE_API}/menu_items/header-menu`);
    };

    getLocaleOptions = (data) => {
        return axios.get(`${BASE_API}/configurable_language/configurable_language`, {
             params: { 
                 "filter[locked]": 0 ,
                 "filter[drupal_internal__id][condition][operator]": "IN",
                 "filter[drupal_internal__id][condition][path]":"drupal_internal__id",
                 "filter[drupal_internal__id][condition][value][0]":"hi",
                 "filter[drupal_internal__id][condition][value][1]":"en",
                } });
    };

    getDashBorardTopNav = (data) => {
        return axios.get(`${BASE_API}/api/dashboard-topnav`, { params: data });
    };

}

export default new TopnavService();
