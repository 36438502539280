import HttpStatus from "constants/HttpStatus";
import { DeepTrimAndCleanUp } from "helpers/DeepTrim";
import API from "./service";

const register = async (parameter,data) => {
  try {
    DeepTrimAndCleanUp({ data, removeEmptyStringKeyFlag: true });
    const response = await API.register(parameter,data)
      .then((res) => res)
      .catch((error) => {
        console.log("error", error);
        return (
          error?.response || {
            status: HttpStatus.NETWORK_CONNECT_TIMEOUT_ERROR,
          }
        );
      });
    if (HttpStatus.SUCCESS.includes(response.status)) {
      data = { ...data, ...response, status: true };
    } else {
      data = { data: [], ...response, status: false };
    }
  } catch (error) {
    console.error("Exception occur in register.register ", error);
    data = { data: [], status: false };
  }
  return data;
};

const getCountries = async (data) => {
  try {
      DeepTrimAndCleanUp({ data, removeEmptyStringKeyFlag: true });
      const response = await API.getCountries(data)
          .then((res) => res)
          .catch((error) => {
              console.log("error", error);
              return (
                  error?.response || {
                      status: HttpStatus.NETWORK_CONNECT_TIMEOUT_ERROR,
                  }
              );
          });
      if (HttpStatus.SUCCESS.includes(response.status)) {
          data = { ...data, ...response.data, status: true };
      } else {
          data = { data: [], status: false };
      }
  } catch (error) {
      console.error("Exception occur in register.getCountries ", error);
      data = { data: [], status: false };
  }
  return data;
};

const getCountriesLoop = async (data,url) => {
  try {
      DeepTrimAndCleanUp({ data, removeEmptyStringKeyFlag: true });
      const response = await API.getCountriesLoop(url)
          .then((res) => res)
          .catch((error) => {
              console.log("error", error);
              return (
                  error?.response || {
                      status: HttpStatus.NETWORK_CONNECT_TIMEOUT_ERROR,
                  }
              );
          });
      if (HttpStatus.SUCCESS.includes(response.status)) {
          data = { ...data, ...response.data, status: true };
      } else {
          data = { data: [], status: false };
      }
  } catch (error) {
      console.error("Exception occur in register.getCountries ", error);
      data = { data: [], status: false };
  }
  return data;
};

const getSubscriptionMonths = async (data) => {
  try {
      DeepTrimAndCleanUp({ data, removeEmptyStringKeyFlag: true });
      const response = await API.getSubscriptionMonths(data)
          .then((res) => res)
          .catch((error) => {
              console.log("error", error);
              return (
                  error?.response || {
                      status: HttpStatus.NETWORK_CONNECT_TIMEOUT_ERROR,
                  }
              );
          });
      if (HttpStatus.SUCCESS.includes(response.status)) {
          data = { ...data, ...response.data, status: true };
      } else {
          data = { data: [], status: false };
      }
  } catch (error) {
      console.error("Exception occur in register.getSubscriptionMonths ", error);
      data = { data: [], status: false };
  }
  return data;
};

const getRegisterDetails = async (data) => {
  try {
      DeepTrimAndCleanUp({ data, removeEmptyStringKeyFlag: true });
      const response = await API.getRegisterDetails(data)
          .then((res) => res)
          .catch((error) => {
              console.log("error", error);
              return (
                  error?.response || {
                      status: HttpStatus.NETWORK_CONNECT_TIMEOUT_ERROR,
                  }
              );
          });
      if (HttpStatus.SUCCESS.includes(response.status)) {
          data = { ...data, ...response.data, status: true };
      } else {
          data = { data: [], status: false };
      }
  } catch (error) {
      console.error("Exception occur in register.getSubscriptionMonths ", error);
      data = { data: [], status: false };
  }
  return data;
};

const exportedObject = {
  register,
  getCountries,
  getCountriesLoop,
  getSubscriptionMonths,
  getRegisterDetails
};

export default exportedObject;
