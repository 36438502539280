import RedirectPath from "../constants/RedirectPath";

export const Footer = {
  bornSmartLogo: "/assets/logos/logo.png",
  facebookLogo: "/assets/img/home/facebook.svg",
  twitterLogo: "/assets/img/home/twitter.png",
  instagramLogo: "/assets/img/home/instagram.jpg",
  youtubeLogo: "/assets/img/home/youtube.png",
  emailIdImage: "/assets/img/footer/mail.png",
  emailId: "talktous@born-smart.com",
  mobileNumberImage: "/assets/img/footer/phone.png",
  mobileNumber: "+91 70459 49635",
  copyRight: "2021 @Born Smart. All Rights Reserved",
  footerDetails: [
    {
      name: "About Us",
      path: RedirectPath.ABOUT_US,
    },
    {
      name: "Why Born Smart",
      path: RedirectPath.WHY_BORN_MART,
    },
    {
      name: "Parenting Tips",
      path: RedirectPath.PARENTING_TIPS,
    },
    {
      name: "Brain Development",
      path: RedirectPath.BRAIN_DEVELOPMENT,
    },
  ],
  footerTwoDetails: [
    {
      name: 'Careers',
      path: RedirectPath.HOME,
    },
    {
      name: 'Terms & Condition',
      path: RedirectPath.HOME,
    },
    {
      name: 'Privacy Policy',
      path: RedirectPath.HOME,
    },
    {
      name: 'Site Map',
      path: RedirectPath.HOME,
    },
    {
      name: 'Disclaimer',
      path: RedirectPath.HOME,
    },
  ]
};

export const FooterOne = [
  {
    "attributes": {
      "title": "About Us",
      "url": RedirectPath.ABOUT_US
    }
  },
  {
    "attributes": {
      "title": "Why Born Smart",
      "url": RedirectPath.WHY_BORN_MART
    }
  },
  {
    "attributes": {
      "title": "Parenting Tips",
      "url": RedirectPath.PARENTING_TIPS
    }
  },
  {
    "attributes": {
      "title": "Brain Development",
      "url": RedirectPath.BRAIN_DEVELOPMENT
    }
  }
];

export const FooterTwo = [
  {
    "attributes": {
      "title": 'Careers',
      "url": RedirectPath.HOME,
    }
  },
  {
    "attributes": {
      "title": 'Terms & Condition',
      "url": RedirectPath.HOME,
    }
  },
  {
    "attributes": {
      "title": 'Privacy Policy',
      "url": RedirectPath.HOME,
    }
  },
  {
    "attributes": {
      "title": 'Site Map',
      "url": RedirectPath.HOME,
    }
  },
  {
    "attributes": {
      "title": 'Disclaimer',
      "url": RedirectPath.HOME,
    }
  },
];


export const ContactDetails = [
  {
    "attributes": {
      "title": 'Email',
      "description": "talktous@born-smart.com",
    }
  }, {
    "attributes": {
      "title": 'Phone',
      "description": "+91 70459 49635",
    }
  }

];