import Crypto from "./Crypto";

const CSRF_TOKEN_KEY = process.env.REACT_APP_CSRF_TOKEN_KEY;
const LOGOUT_TOKEN_KEY = process.env.REACT_APP_LOGOUT_TOKEN_KEY;

const getAllCookies = () => {
  const pairs = document.cookie.split(";");
  const cookies = {};
  pairs.forEach((obj) => {
    const pair = obj.split("=");
    cookies[String(pair[0]).trim()] = unescape(pair[1]);
  });
  return cookies;
};

export const setCookie = (cname, cvalue, exdays) => {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 9 * 60 * 60 * 1000);
  // const expires = "expires=".concat(d.toUTCString());
  document.cookie = cname
    .concat("=")
    .concat(cvalue)
    .concat(";")
    // .concat(expires)
    .concat(";path=/");
  window.name = JSON.stringify(getAllCookies());
};

const getCookie = (cname) => {
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");
  let data = ca.find((obj) => obj.split("=")?.[0].trim() === cname);
  data = (data || "")?.split("=")?.[1]?.trim() || "";
  return data;
};

const deleteCookie = (name) => {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};

export const storeCsrfToken = (token) => {
  setCookie(CSRF_TOKEN_KEY, token, 1);
};

export const getCsrfToken = () => {
  return getCookie(CSRF_TOKEN_KEY) || "";
};

export const storeLogoutToken = (token) => {
  setCookie(LOGOUT_TOKEN_KEY, token, 1);
};

export const getLogoutToken = () => {
  return getCookie(LOGOUT_TOKEN_KEY) || "";
};

export const getUser = () => {
  return JSON.parse(Crypto.decrypt(localStorage.getItem('current_user'))) || {};
};

export const getUserId = () => {
  return getUser().uid || 0;
};

export const deleteToken = () => {
  deleteCookie(CSRF_TOKEN_KEY);
  deleteCookie(LOGOUT_TOKEN_KEY);
  localStorage.removeItem('current_user');
  localStorage.removeItem('subscription_end_month');
  localStorage.removeItem('first_name');
  localStorage.removeItem('last_name');
  localStorage.removeItem('subscription');
  localStorage.removeItem('subscription_condition');
};
