import axios from "axios";
import { getCsrfToken } from "helpers/Storage";

const BASE_API = process.env.REACT_APP_BASE_API;
const APP_DOMAIN_URL = process.env.REACT_APP_DOMAIN_URL;

const headers = {
  "X-CSRF-Token": getCsrfToken(),
};

class ProfileService {
  getProfileUser = (data) => {
    return axios.post(
      `${APP_DOMAIN_URL}/api/smart-born/get/user?_format=json`,
      data,
      { headers }
    );
  };

  updateParent = (data) => {
    return axios.post(
      `${APP_DOMAIN_URL}/api/smart-born/update/user?_format=json`,
      data,
      { headers }
    );
  };


  updatePassword = (data) => {
    return axios.post(
      `${APP_DOMAIN_URL}/api/smart-born/update/user?_format=json`,
      data,
      { headers }
    );
  };

  updateChild = (data) => {
    return axios.post(
      `${APP_DOMAIN_URL}/api/smart-born/update/user?_format=json`,
      data,
      { headers }
    );
  };

  getProfile = (data) => {
    return axios.get(`${BASE_API}/api/profile`, { params: data });
  };

  getChildDetails = (data) => {
    return axios.get(`${BASE_API}/api/childDetails`, { params: data });
  };

  updateProfile = (data) => {
    return axios.put(`${BASE_API}/api/updateProfile`, data);
  };

  updateChildDetails = (data) => {
    return axios.put(`${BASE_API}/api/updateChildDetails`, data);
  };

//   updatePassword = (data) => {
//     return axios.put(`${BASE_API}/api/updatePassword`, data);
//   };

  deleteChild = (data) => {
    return axios.delete(`${BASE_API}/api/deleteChild`, { params: data });
  };
}

export default new ProfileService();
