import axios from "axios";

const BASE_API = process.env.REACT_APP_BASE_API;
const AUTH_API = process.env.REACT_APP_AUTH_BASE_API;

class RegisterService {

  register = (parameter, data) => {
    return axios.post(`${AUTH_API}/register`, data, { params: parameter });
  };

  getCountries = (data) => {
    return axios.get(`${BASE_API}/taxonomy_term/countries?fields[taxonomy_term--countries]=name,field_country_code,field_isd_code&sort=name`, { params: data });
  };

  getCountriesLoop = async (url) => {
    return axios.get(url?.href);
  };

  getSubscriptionMonths = (data) => {
    return axios.get(`${BASE_API}/taxonomy_term/subscription`, { params: data });
  };

  getRegisterDetails = (data) => {
    return axios.get(`${BASE_API}/node/user_registration`, { params: data });
  };

}

export default new RegisterService();
