import axios from "axios";
import Crypto from "../../helpers/Crypto";
import { deleteToken, storeCsrfToken, storeLogoutToken } from "helpers/Storage";
import { SIGNOUT } from "./types";
import RedirectPath from "constants/RedirectPath";
import { displayNotification } from "components/common/react-notifications/genericNotification";
import APIService from "API";

const signin =
    (dispatch) =>
        async ({ email, password }) => {
            try {
                const res = await APIService.AuthAPI.signin({
                    name: email,
                    pass: password
                });
                if (res.status) {
                    // console.log(res);
                    // console.log(res.headers['set-cookie']);
                    await storeCsrfToken(res.csrf_token);
                    await storeLogoutToken(res.logout_token);
                    axios.defaults.headers.post['X-CSRF-Token'] = res.csrf_token;
                    await localStorage.setItem("current_user", Crypto.encrypt(JSON.stringify(res?.current_user)));
                    window.location.href = RedirectPath.LOGIN;
                } else {
                    displayNotification("Login", res.message, "error");
                }
            } catch (error) {
                displayNotification("Login", "Something went wrong with sign in", "error");
            }
        };

const signout = (dispatch) => async () => {
    await deleteToken();
    dispatch({ type: SIGNOUT });
};

const exportedObject = {
    signin,
    signout
};

export default exportedObject;
