import HttpStatus from "constants/HttpStatus";
import { DeepTrimAndCleanUp } from "helpers/DeepTrim";
import API from "./service";


const getFaqMainDetails = async (data) => {
    try {
        DeepTrimAndCleanUp({ data, removeEmptyStringKeyFlag: true });
        const response = await API.getFaqMainDetails(data)
            .then((res) => res)
            .catch((error) => {
                console.log("error", error);
                return (
                    error?.response || {
                        status: HttpStatus.NETWORK_CONNECT_TIMEOUT_ERROR,
                    }
                );
            });
        if (HttpStatus.SUCCESS.includes(response.status)) {
            data = { ...data, ...response.data, status: true };
        } else {
            data = { ...data, status: false };
        }
    } catch (error) {
        console.error("Exception occur in Contact-Us ", error);
        data = { data: [], status: false };
    }
    return data;
};
const getFagData = async (data) => {
    try {
        DeepTrimAndCleanUp({ data, removeEmptyStringKeyFlag: true });
        const response = await API.getFagData(data)
            .then((res) => res)
            .catch((error) => {
                console.log("error", error);
                return (
                    error?.response || {
                        status: HttpStatus.NETWORK_CONNECT_TIMEOUT_ERROR,
                    }
                );
            });
        if (HttpStatus.SUCCESS.includes(response.status)) {
            data = { ...data, ...response.data, status: true };
        } else {
            data = { ...data, status: false };
        }
    } catch (error) {
        console.error("Exception occur in Contact-Us ", error);
        data = { data: [], status: false };
    }
    return data;
};

const exportedObject = {
    getFaqMainDetails,
    getFagData
};

export default exportedObject;