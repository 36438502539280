import {all} from 'redux-saga/effects';

import subscriptionSagas from './subscription/saga';
import firstNameSagas from './parent-details/saga';

export default function* rootSaga() {
    yield all([
        subscriptionSagas(),
        firstNameSagas()
    ]);
}