import HttpStatus from "constants/HttpStatus";
import {
    FooterOne,
    FooterTwo,
    ContactDetails
} from "data/footer";
import { DeepTrimAndCleanUp } from "helpers/DeepTrim";
import API from "./service";


const getFooterOne = async (data) => {
    try {
        DeepTrimAndCleanUp({ data, removeEmptyStringKeyFlag: true });
        const response = await API.getFooterOne(data)
            .then((res) => res)
            .catch((error) => {
                console.log("error", error);
                return (
                    error?.response || {
                        status: HttpStatus.NETWORK_CONNECT_TIMEOUT_ERROR,
                    }
                );
            });
        if (HttpStatus.SUCCESS.includes(response.status)) {
            data = { ...data, ...response.data, status: true };
        } else {
            data = { data: FooterOne, status: false };
        }
    } catch (error) {
        console.error("Exception occur in home.getFooterOne ", error);
        data = { data: FooterOne, status: false };
    }
    return data;
};

const getFooterTwo = async (data) => {
    try {
        DeepTrimAndCleanUp({ data, removeEmptyStringKeyFlag: true });
        const response = await API.getFooterTwo(data)
            .then((res) => res)
            .catch((error) => {
                console.log("error", error);
                return (
                    error?.response || {
                        status: HttpStatus.NETWORK_CONNECT_TIMEOUT_ERROR,
                    }
                );
            });
        if (HttpStatus.SUCCESS.includes(response.status)) {
            data = { ...data, ...response.data, status: true };
        } else {
            data = { data: FooterTwo, status: false };
        }
    } catch (error) {
        console.error("Exception occur in home.getFooterTwo ", error);
        data = { data: FooterTwo, status: false };
    }
    return data;
};

const getSocialMedia = async (data) => {
    try {
        DeepTrimAndCleanUp({ data, removeEmptyStringKeyFlag: true });
        const response = await API.getSocialMedia(data)
            .then((res) => res)
            .catch((error) => {
                console.log("error", error);
                return (
                    error?.response || {
                        status: HttpStatus.NETWORK_CONNECT_TIMEOUT_ERROR,
                    }
                );
            });
        if (HttpStatus.SUCCESS.includes(response.status)) {
            data = { ...data, ...response.data, status: true };
        } else {
            data = { data: ContactDetails, status: false };
        }
    } catch (error) {
        console.error("Exception occur in home.getSocialMedia ", error);
        data = { data: ContactDetails, status: false };
    }
    return data;
};

const getContactDetails = async (data) => {
    try {
        DeepTrimAndCleanUp({ data, removeEmptyStringKeyFlag: true });
        const response = await API.getContactDetails(data)
            .then((res) => res)
            .catch((error) => {
                console.log("error", error);
                return (
                    error?.response || {
                        status: HttpStatus.NETWORK_CONNECT_TIMEOUT_ERROR,
                    }
                );
            });
        if (HttpStatus.SUCCESS.includes(response.status)) {
            data = { ...data, ...response.data, status: true };
        } else {
            data = { data: ContactDetails, status: false };
        }
    } catch (error) {
        console.error("Exception occur in home.getContactDetails ", error);
        data = { data: ContactDetails, status: false };
    }
    return data;
};

const getPrivacyPolicy= async (data) => {
    try {
        DeepTrimAndCleanUp({ data, removeEmptyStringKeyFlag: true });
        const response = await API.getPrivacyPolicy(data)
            .then((res) => res)
            .catch((error) => {
                console.log("error", error);
                return (
                    error?.response || {
                        status: HttpStatus.NETWORK_CONNECT_TIMEOUT_ERROR,
                    }
                );
            });
        if (HttpStatus.SUCCESS.includes(response.status)) {
            data = { ...data, ...response.data, status: true };
        } else {
            data = { data: ContactDetails, status: false };
        }
    } catch (error) {
        console.error("Exception occur in home.getContactDetails ", error);
        data = { data: ContactDetails, status: false };
    }
    return data;
};

const getTermsAndCondition= async (data) => {
    try {
        DeepTrimAndCleanUp({ data, removeEmptyStringKeyFlag: true });
        const response = await API.getTermsAndCondition(data)
            .then((res) => res)
            .catch((error) => {
                console.log("error", error);
                return (
                    error?.response || {
                        status: HttpStatus.NETWORK_CONNECT_TIMEOUT_ERROR,
                    }
                );
            });
        if (HttpStatus.SUCCESS.includes(response.status)) {
            data = { ...data, ...response.data, status: true };
        } else {
            data = { data: ContactDetails, status: false };
        }
    } catch (error) {
        console.error("Exception occur in home.getContactDetails ", error);
        data = { data: ContactDetails, status: false };
    }
    return data;
};

const exportedObject = {
    getFooterOne,
    getFooterTwo,
    getSocialMedia,
    getContactDetails,
    getPrivacyPolicy,
    getTermsAndCondition
};

export default exportedObject;