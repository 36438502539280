import axios from "axios";

const AUTH_API = process.env.REACT_APP_AUTH_BASE_API;

class AuthService {
  signin = async (data) => {
    return axios.post(`${AUTH_API}/login?_format=json`, data);

    // fetch(`${AUTH_API}/login?_format=json`, {
    //   method: "POST", // or 'PUT',
    //   headers: {
    //     "Content-Type": "application/json",
    //     credentials: "same-origin"
    //   },
    //   credentials: 'same-origin',
    //   body: JSON.stringify(data),
    // })
    //   .then((response) => {
    //     console.log("response", response);
    //     return response;
    //   })
    //   .then((data) => {
    //     console.log("Success:", data);
    //   });
    // };
  };
}

export default new AuthService();
