import HttpStatus from "constants/HttpStatus";
import { DeepTrimAndCleanUp } from "helpers/DeepTrim";
import API from "./service";

const signin = async (data) => {
  try {
    DeepTrimAndCleanUp({ data, removeEmptyStringKeyFlag: true });
    const response = await API.signin(data)
      .then((res) => res)
      .catch((error) => {
        console.log("error", error);
        return (
          error?.response || {
            status: HttpStatus.NETWORK_CONNECT_TIMEOUT_ERROR,
          }
        );
      });
      // console.log('response >> ',response);
    if (HttpStatus.SUCCESS.includes(response.status)) 
    {
      data = { ...data, ...response.data, status: true };
    } else {
      data = { ...data,...response.data, status: false };
    }
  } catch (error) {
    console.error("Exception occur in Auth.signin ", error);
    data = { data: [], status: false };
  }
  return data;
};

const exportedObject = {
  signin
};

export default exportedObject;
