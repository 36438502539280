import axios from "axios";

const BASE_API = process.env.REACT_APP_BASE_API;

class AboutUsService {
  aboutUs = async (data) => {
    return axios.get(`${BASE_API}/node/About_Us`, { params: data });
  };

  ourExpertsData = async (data) => {
    return axios.get(`${BASE_API}/node/our_expert`, { params: data });
  };
}

export default new AboutUsService();