import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import {Provider as ReduxProvider} from 'react-redux';
import { configureStore } from 'reducer/store';
import * as serviceWorker from 'serviceWorker';
import { isMobile, isTablet } from "react-device-detect";
import DeviceOrientation, { Orientation } from "react-screen-orientation";
import { Provider as LocaleContext } from "context/appConfig/context";
import { Provider as AuthContext } from "context/auth/context";

const AppMobile = React.lazy(() =>
  import(/* webpackChunkName: "App" */ "./AppMobile")
);

const AppWeb = React.lazy(() =>
  import(/* webpackChunkName: "App" */ "./AppWeb")
);


ReactDOM.render(
  <Suspense fallback={<div className="loading" />}>
    <ReduxProvider store={configureStore()}>
    <LocaleContext>
      <AuthContext>
          {isTablet ? (
            <DeviceOrientation>
              <Orientation orientation="landscape" alwaysRender={false}>
                <AppWeb />
              </Orientation>
              <Orientation orientation="portrait" alwaysRender={false}>
                <AppMobile />
              </Orientation>
            </DeviceOrientation>
          ) : isMobile ? (
            <AppMobile />
          ) : (
            <AppWeb />
          )}
      </AuthContext>
    </LocaleContext>
    </ReduxProvider>
  </Suspense>,
  document.getElementById("root")
);
/*
 * If you want your app to work offline and load faster, you can change
 * unregister() to register() below. Note this comes with some pitfalls.
 * Learn more about service workers: https://bit.ly/CRA-PWA
 */
serviceWorker.unregister();

