import CreateContext from "../createDataContext";
import { CHANGE_LOCALE } from "./types";
import { getCurrentLanguage } from 'helpers/Utils';
import Actions from "./actions";

const InitialState = { locale: getCurrentLanguage() };

const LocaleReducer = (state, action) => {
    switch (action.type) {
        case CHANGE_LOCALE:
            return { ...state, locale: action.payload };
        default:
            return state;
    }
};

/**
 *  export Context API
 */
export const { Provider, Context } = CreateContext(
    LocaleReducer,
    Actions,
    InitialState
);