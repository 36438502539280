import axios from "axios";

const BASE_API = process.env.REACT_APP_BASE_API;

class OfferingService {

    getOffering = (data) => {
    return axios.get(`${BASE_API}/node/offerings`, { params: data });
  };

}
export default new OfferingService();
