import CreateContext from "../createDataContext";
import { SIGNIN, SIGNOUT } from "./types";
import Actions from "./actions";

const InitialState = { user: null, errorMessage: '' };

const AuthReducer = (state, action) => {
    switch (action.type) {
        case SIGNIN:
            return { user: action.payload };
        case SIGNOUT:
            return { user: null };
        default:
            return state;
    }
};

/**
 *  export Context API
 */
export const { Provider, Context } = CreateContext(
    AuthReducer,
    Actions,
    InitialState
);
