import axios from "axios";
import { getCsrfToken } from "helpers/Storage";

const BASE_API = process.env.REACT_APP_DOMAIN_URL;

const headers = {
  "X-CSRF-Token": getCsrfToken(),
};

class videoHistoryService {
  getHistory = (data) => {
    return axios.post(
      `${BASE_API}/api/smart-born/subscription-video/history/get?_format=json`,
       data,
      { headers }
    );
  };

  getVideoHistory = (data) => {
    return axios.post(
      `${BASE_API}/api/smart-born/subscription-video/history/get?_format=json`,
       data,
      { headers }
    );
  };

  setCompleteVideo = (data) => {
    return axios.post(
      `${BASE_API}/api/smart-born/subscription-video/history/insert?_format=json`,
       data,
      { headers }
    );
  };


}

export default new videoHistoryService();
