import "./assets/css/sass/main.scss";
import "./assets/css/sass/responsive.scss";
import "./assets/css/vendor/bootstrap.min.css";
import "./assets/css/vendor/bootstrap.rtl.only.min.css";
import "react-datepicker/dist/react-datepicker.css";
import "font-awesome/css/font-awesome.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-rangeslider/lib/index.css";
import 'react-notifications/lib/notifications.css';

const render = () => {
  require("./AppRenderer");
};
render();
