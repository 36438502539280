import {combineReducers} from 'redux';

import subscription from './subscription/reducer';
import firstName from './parent-details/reducer';

const reducers = combineReducers({
    subscription,
    firstName
});

export default reducers;