import axios from "axios";

const BASE_API = process.env.REACT_APP_BASE_API;

class ParentingTipsService {

    getParentingTips = (data) => {
        return axios.get(`${BASE_API}/taxonomy_term/parenting_tips`, { params: data });
    };

    getChildCategories = (data) => {
        return axios.get(`${BASE_API}/taxonomy_term/parenting_tips`, { params: data });
    };

    getChildCategoriesDetails = (data) => {
        return axios.get(`${BASE_API}/node/parenting_tips`, { params: data });
    };

}

export default new ParentingTipsService();