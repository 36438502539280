import axios from "axios";

const BASE_API = process.env.REACT_APP_BASE_API;

class OurExpertsService {

  ourExperts = (data) => {
    return axios.post(`${BASE_API}/api/ourExperts`, data);
  };

  getOurExperts = (data) => {
    return axios.get(`${BASE_API}/node/our_expert`, { params: data });
  };

}

export default new OurExpertsService();
