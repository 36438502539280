import { setCurrentLanguage } from 'helpers/Utils';
import { CHANGE_LOCALE } from './types'

const changeLocale =
    (dispatch) => {
        return ((locale) => {
            setCurrentLanguage(locale);
            dispatch({ type: CHANGE_LOCALE, payload: locale });
        }
        )
    }

const exportedObject = {
    changeLocale
};

export default exportedObject;
