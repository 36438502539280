export const DASHBOARD_TOPNAV = {
  subscriptionDetails: "Your Subscription is due on ",
  subscriptionNumber: "1 Subscription",
  subscriptionPaidOrFree: "Paid",
  subscriberName: "Vinod Sharma",
};

export const OUR_EXPERTS = [
  {
    photo: "/assets/img/experts/toni.jpg",
    name: "Toni Christie",
    post: "Director of the Childspace Early Childhood Institute in Wellington, NZ",
    country: "New Zealand",
    quote:
      "“Babies and toddlers require respect in their interactions with caregivers. This leads to happy brains and relationships.",
    details:
      "She is passionate about early childhood education, leadership, environments, infants, teamwork, communication, and advocacy for children, families, and early childhood educators. Toni holds a Master of Education with merit from Victoria University in Wellington.<span class=text-style-1>Toni enjoys her many roles in life as wife, mother, author, keynote speaker, marriage celebrant, Justice of the Peace, editor, musician, artist, and mentor.</span>",
  },
  {
    photo: "/assets/img/experts/alice.jpg",
    name: "Alice Sharp",
    post: "Educational and Play Specialist",
    country: "Wales, England",
    quote: "“What we do matters; every minute of every day!",
    details:
      "Alice is an Educational Specialist in the field of Early Childhood. She started life as a civil servant before becoming a Primary school teacher. She then took up a Senior Lecturer post and led on projects for the Scottish government and UK government for working with parents.<span class=text-style-1>She is owner of Experiential Play, a training company that delivers qualifications at every level from basic to Degree equivalent. She is often a guest lecturer at various universities and colleges and is a world renowned key note conference speaker. </span><span class=text-style-1>She is the author of various books and articles for national magazines and publications.</span>",
  },
  {
    photo: "/assets/img/experts/richard.jpg",
    name: "Richard Cohen",
    post: "Zen and the Power of Early Childhood Education",
    country: "USA",
    quote:
      "“Creativity in our interactions with children keeps them active and engaged.",
    details:
      "has proudly been an early childhood professional for almost 40 years. For the last 25 years, Richard has traveled the world as a consultant, coach and motivational speaker/instructor for communities of early childhood caregivers and parents. He curates his viral social media presence, “Zen and the Art of Early Childhood Education” on Facebook, Twitter, YouTube and Instagram, offers a catalogue of online ECE professional development courses and hosts community sing-alongs.<span class=text-style-1>Richard is currently a college professor in the United States. Across his career, Richard has made many important contributions to the field of early childhood education including </span>",
  },
  {
    photo: "/assets/img/experts/swati.jpg",
    name: "Dr. Swati Popat Vats",
    post: "President Early Childhood Association - India, Podar Education Network.",
    country: "India",
    quote:
      "“Nurturing children’s brain development is the best gift that parents can give. It only takes a few games everyday.",
    details:
      "is a global early childhood expert with over 34 years of experience. She designs developmentally appropriate curriculums and she has coined the term Kiducation as the guiding philosophy for her curriculums and has recently developed a non-screen based pre-coding curriculum for early years that helps children learn how to ‘learn and think’. Dr. Vats has also authored two coding books; Yash and Yashika learn about coding and Cod and Codie love to code, published by Scholastic. She is a global expert on the international advisory team for Kidzania.<span class=text-style-1>She has been involved in an advisory capacity in early years curriculum development for Cambridge Early Years Curriculum and TATASKY Actve Whiz Kids television channel.</span>",
  },
  {
    photo: "/assets/img/experts/sue_atkins.jpg",
    name: "Sue Atkins",
    post: "Broadcaster  |  Speaker  |  Author",
    country: "UK",
    quote:
      "“She is also the parenting expert for BBC Radio Stations around the UK.",
    details:
      "Sue Atkins is an internationally recognised Parenting Expert, Broadcaster, Speaker and Author of the Amazon best-selling books “Parenting Made Easy – How to Raise Happy Children’ & ‘Raising Happy Children for Dummies’ one in the famous black and yellow series, as well as the ‘Can Do Kid’s Journal: Discover your Confidence Superpower’ & ‘The Divorce Journal for Kids’ Sue is the Parenting Expert for Disney Family and records monthly podcasts and Facebook Live Tea Parties around ‘Parenting Hacks,’ and regularly appears on the award-winning flagship ITV show ‘This Morning,’ Good Morning Britain and ‘Sky News.’",
  },
  {
    photo: "/assets/img/experts/rosely_duffy.jpg",
    name: "Roslyn Duffy",
    post: "ECE Trainer  |  Speaker  |  Author",
    country: "USA",
    quote: "“What we do matters; every minute of every day!",
    details:
      "Her (Positive Discipline: The First Three Years and Positive Discipline for Preschoolers, (both Random House books) have been translated into over a dozen languages and her magazine column, From A Parent’s Perspective has been compiled into the text: Top Ten Preschool Parenting Problems (Exchange Press).<span class=text-style-1>Roslyn currently serves on the Seattle League of Women Voters Board of Directors; Kids First Executive Council; Childcare Resources Advocacy Committee; and TINFA Directors Circle.</span>",
  },
];
