import axios from "axios";

const BASE_API = process.env.REACT_APP_BASE_API;

class subscriptionService {
    getSubscription = () => {
    return axios.get(`${BASE_API}/test`);
  };
}

export default new subscriptionService();
