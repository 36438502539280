import axios from "axios";

const BASE_API = process.env.REACT_APP_BASE_API;

class AdvantageService {
    getAdvantage = async (data) => {
    return axios.get(`${BASE_API}/node/advantage`, { params: data });
  };

}

export default new AdvantageService();