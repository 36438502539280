import axios from "axios";

const BASE_API = process.env.REACT_APP_BASE_API;
const REACT_APP_DOMAIN_URL = process.env.REACT_APP_DOMAIN_URL;
class AuthService {
  contactUsAppDetails = async (data) => {
    return axios.get(`${BASE_API}/node/Contact_us_download_app`, {
      params: data,
    });
  };

  askUsForm = async (parameter, data) => {
    return axios.post(`${REACT_APP_DOMAIN_URL}/webform_rest/submit`, data, {
      params: parameter,
    });
  };
}

export default new AuthService();
