import axios from "axios";

const BASE_API = process.env.REACT_APP_BASE_API;

class HomeService {

  getBanners = (data) => {
    return axios.get(`${BASE_API}/node/homepage_banner`, { params: data });
  };

  getAboutUsDetails = (data) => {
    return axios.get(`${BASE_API}/node/introduction`, { params: data });
  };

  getAboutUsSubIntroduction = (data) => {
    return axios.get(`${BASE_API}/node/introduction`, { params: data });
  };

  getExprienceBornSmartDetails = (data) => {
    return axios.get(`${BASE_API}/taxonomy_term/age`, { params: data });
  };

  getExprienceBornSmartVideo = (data) => {
    return axios.get(`${BASE_API}/node/video_gallery`, { params: data });
  };

  getExprienceBornSmartCombine = (data) => {
    return axios.get(`${BASE_API}/node/video_gallery`, { params: data });
  };

  getWhyChooseBornSmartDetails = (data) => {
    return axios.get(`${BASE_API}/api/why-choose-born-smart`, { params: data });
  };

  getParentingMadeEasyDetails = (data) => {
    return axios.get(`${BASE_API}/api/parenting-details`, { params: data });
  };

  getJoinBornSmartDetails = (data) => {
    return axios.get(`${BASE_API}/api/getJoinBornSmartDetails`, { params: data });
  };

  getJoinBornSmartFreeDetails = (data) => {
    return axios.get(`${BASE_API}/node/join_born_smart`, { params: data });
  };

  getJoinBornSmartPaidDetails = (data) => {
    return axios.get(`${BASE_API}/node/join_born_smart`, { params: data });
  };

  getKnowYourChildDetails = (data) => {
    return axios.get(`${BASE_API}/node/know_your_child`, { params: data });
  };

  getVideosDetails = (data) => {
    return axios.get(`${BASE_API}/node/testinmonial`, { params: data });
  };

  getNewsLetterDetails = (data) => {
    return axios.get(`${BASE_API}/api/news-letter`, { params: data });
  };

  getFaqDetails = (data) => {
    return axios.get(`${BASE_API}/node/faq`);
  };

  getBabyDevelopmentMonth = (data) => {
    return axios.get(`${BASE_API}/api/agewise-baby-details`, { params: data });
  };

  getBabyBodyPartDetails = (data) => {
    return axios.get(`${BASE_API}/api/baby-body-part-details`, { params: data });
  };

  getParentingMadeEasy(data) {
    return axios.get(`${BASE_API}/node/homepage_dashboard`, { params: data });
  }

}

export default new HomeService();
