import HttpStatus from "constants/HttpStatus";
import { PARENT_CHILD_DETAILS, ACTIVITIES, DID_YOU_KNOW } from "data/dashboard";
import { DeepTrimAndCleanUp } from "helpers/DeepTrim";
import API from "./service";

const getSubscriptionVideoList = async (data) => {
    try {
        DeepTrimAndCleanUp({ data, removeEmptyStringKeyFlag: true });
        const response = await API.getSubscriptionVideoList(data)
            .then((res) => res)
            .catch((error) => {
                console.log("error", error);
                return (
                    error?.response || {
                        status: HttpStatus.NETWORK_CONNECT_TIMEOUT_ERROR,
                    }
                );
            });
        if (HttpStatus.SUCCESS.includes(response.status)) {
            data = { ...data, ...response.data, status: true };
        } else {
            data = { data: PARENT_CHILD_DETAILS, status: false };
        }
    } catch (error) {
        console.error("Exception occur in dashboard.getSubscriptionVideoList ", error);
        data = { data: PARENT_CHILD_DETAILS, status: false };
    }
    return data;
};

const getVideo = async (data) => {
    try {
        DeepTrimAndCleanUp({ data, removeEmptyStringKeyFlag: true });
        const response = await API.getVideo(data)
            .then((res) => res)
            .catch((error) => {
                console.log("error", error);
                return (
                    error?.response || {
                        status: HttpStatus.NETWORK_CONNECT_TIMEOUT_ERROR,
                    }
                );
            });
        if (HttpStatus.SUCCESS.includes(response.status)) {
            data = { ...data, ...response.data, status: true };
        } else {
            data = { data: PARENT_CHILD_DETAILS, status: false };
        }
    } catch (error) {
        console.error("Exception occur in dashboard.getVideo ", error);
        data = { data: PARENT_CHILD_DETAILS, status: false };
    }
    return data;
};

const getChildList = async (data) => {
    try {
        DeepTrimAndCleanUp({ data, removeEmptyStringKeyFlag: true });
        const response = await API.getChildList(data)
            .then((res) => res)
            .catch((error) => {
                console.log("error", error);
                return (
                    error?.response || {
                        status: HttpStatus.NETWORK_CONNECT_TIMEOUT_ERROR,
                    }
                );
            });
        if (HttpStatus.SUCCESS.includes(response.status)) {
            data = { ...data, ...response.data, status: true };
        } else {
            data = { data:[] , status: false };
        }
    } catch (error) {
        console.error("Exception occur in dashboard.getChildList ", error);
        data = { data: PARENT_CHILD_DETAILS, status: false };
    }
    return data;
};




const getParentChildDetails = async (data) => {
    try {
        DeepTrimAndCleanUp({ data, removeEmptyStringKeyFlag: true });
        const response = await API.getParentChildDetails(data)
            .then((res) => res)
            .catch((error) => {
                console.log("error", error);
                return (
                    error?.response || {
                        status: HttpStatus.NETWORK_CONNECT_TIMEOUT_ERROR,
                    }
                );
            });
        if (HttpStatus.SUCCESS.includes(response.status)) {
            data = { ...data, ...response.data, status: true };
        } else {
            data = { data: PARENT_CHILD_DETAILS, status: false };
        }
    } catch (error) {
        console.error("Exception occur in dashboard.getParentChildDetails ", error);
        data = { data: PARENT_CHILD_DETAILS, status: false };
    }
    return data;
};

const getActivities = async (data) => {
    try {
        DeepTrimAndCleanUp({ data, removeEmptyStringKeyFlag: true });
        const response = await API.getActivities(data)
            .then((res) => res)
            .catch((error) => {
                console.log("error", error);
                return (
                    error?.response || {
                        status: HttpStatus.NETWORK_CONNECT_TIMEOUT_ERROR,
                    }
                );
            });
        if (HttpStatus.SUCCESS.includes(response.status)) {
            data = { ...data, ...response.data, status: true };
        } else {
            data = { data: ACTIVITIES, status: false };
        }
    } catch (error) {
        console.error("Exception occur in dashboard.getActivities ", error);
        data = { data: ACTIVITIES, status: false };
    }
    return data;
};

const getActivitiesDetails = async (data) => {
    try {
        DeepTrimAndCleanUp({ data, removeEmptyStringKeyFlag: true });
        const response = await API.getActivitiesDetails(data)
            .then((res) => res)
            .catch((error) => {
                console.log("error", error);
                return (
                    error?.response || {
                        status: HttpStatus.NETWORK_CONNECT_TIMEOUT_ERROR,
                    }
                );
            });
        if (HttpStatus.SUCCESS.includes(response.status)) {
            data = { ...data, ...response.data, status: true };
        } else {
            data = { data: ACTIVITIES?.find(activity => activity?.month === data?.month), status: false };
        }
    } catch (error) {
        console.error("Exception occur in dashboard.getActivitiesDetails ", error);
        data = { data: ACTIVITIES?.find(activity => activity?.month === data?.month), status: false };
    }
    return data;
};

const getDidYouKnowDetails = async (data) => {
    try {
        DeepTrimAndCleanUp({ data, removeEmptyStringKeyFlag: true });
        const response = await API.getDidYouKnowDetails(data)
            .then((res) => res)
            .catch((error) => {
                console.log("error", error);
                return (
                    error?.response || {
                        status: HttpStatus.NETWORK_CONNECT_TIMEOUT_ERROR,
                    }
                );
            });
        if (HttpStatus.SUCCESS.includes(response.status)) {
            data = { ...data, ...response.data, status: true };
        } else {
            data = { data: DID_YOU_KNOW, status: false };
        }
    } catch (error) {
        console.error("Exception occur in dashboard.getDidYouKnowDetails ", error);
        data = { data: DID_YOU_KNOW, status: false };
    }
    return data;
};

const exportedObject = {
    getSubscriptionVideoList,
    getVideo,
    getChildList,
    getParentChildDetails,
    getActivities,
    getActivitiesDetails,
    getDidYouKnowDetails
};

export default exportedObject;