import Crypto from "crypto-js";

const encrypt = (value) => {
    try {
        let encodedValue = Crypto.TripleDES.encrypt(String(value), "OpenSpace").toString();
        encodedValue = encodedValue.replace(/\//g, "_")
        return encodedValue
    } catch (error) {
        console.log(error);
        return false
    }
}

const decrypt = (value) => {
    try {
        value = value.replace(/_/g, "/")
        let decodedValue = Crypto.TripleDES.decrypt(String(value), "OpenSpace");
        decodedValue = decodedValue.toString(Crypto.enc.Utf8);
        return decodedValue
    } catch (error) {
        console.log(error);
        return false
    }
}

const exportDefault = {
    encrypt,
    decrypt
}

export default (exportDefault)