import HttpStatus from "constants/HttpStatus";
import { DeepTrimAndCleanUp } from "helpers/DeepTrim";
import API from "./service";


const getHistory = async (data) => {
    try {
        DeepTrimAndCleanUp({ data, removeEmptyStringKeyFlag: true });
        const response = await API.getHistory(data)
            .then((res) => res)
            .catch((error) => {
                console.log("error", error);
                return (
                    error?.response || {
                        status: HttpStatus.NETWORK_CONNECT_TIMEOUT_ERROR,
                    }
                );
            });
        if (HttpStatus.SUCCESS.includes(response.status)) {
            data = [ ...response.data ];
        } else {
            data = [];
        }
    } catch (error) {
        console.error("Exception occur in home.getBanners ", error);
        data = { data: [], status: false };
    }
    return data;
};

const getVideoHistory = async (data) => {
    try {
        DeepTrimAndCleanUp({ data, removeEmptyStringKeyFlag: true });
        const response = await API.getVideoHistory(data)
            .then((res) => res)
            .catch((error) => {
                console.log("error", error);
                return (
                    error?.response || {
                        status: HttpStatus.NETWORK_CONNECT_TIMEOUT_ERROR,
                    }
                );
            });
        if (HttpStatus.SUCCESS.includes(response.status)) {
            data = [ ...response.data ];
        } else {
            data = [];
        }
    } catch (error) {
        console.error("Exception occur in home.getBanners ", error);
        data = { data: [], status: false };
    }
    return data;
};

const setCompleteVideo = async (data) => {
    try {
        DeepTrimAndCleanUp({ data, removeEmptyStringKeyFlag: true });
        const response = await API.setCompleteVideo(data)
            .then((res) => res)
            .catch((error) => {
                console.log("error", error);
                return (
                    error?.response || {
                        status: HttpStatus.NETWORK_CONNECT_TIMEOUT_ERROR,
                    }
                );
            });
        if (HttpStatus.SUCCESS.includes(response.status)) {
            data = { ...data, ...response.data, status: true };
        } else {
            data = { data: [], status: false };
        }
    } catch (error) {
        console.error("Exception occur in video-history.setCompleteVideo ", error);
        data = { data: [], status: false };
    }
    return data;
};



const exportedObject = {
    getHistory,
    getVideoHistory,
    setCompleteVideo,
};

export default exportedObject;