import HttpStatus from "constants/HttpStatus";
import { DASHBOARD_TOPNAV } from "data/topnav";
import { DeepTrimAndCleanUp } from "helpers/DeepTrim";
import API from "./service";

const getTopNavs = async (data) => {
    try {
        DeepTrimAndCleanUp({ data, removeEmptyStringKeyFlag: true });
        const response = await API.getTopNavs(data)
            .then((res) => res)
            .catch((error) => {
                console.log("error", error);
                return (
                    error?.response || {
                        status: HttpStatus.NETWORK_CONNECT_TIMEOUT_ERROR,
                    }
                );
            });
        if (HttpStatus.SUCCESS.includes(response.status)) {
            const responseData = (response.data?.data || []).map(data => {
                data = {
                    name: data?.attributes?.title,
                    path: data?.attributes?.url
                }
                return data;
            })
            data = { data: responseData, status: true };
        } else {
            data = { data: [], status: false };
        }
    } catch (error) {
        console.error("Exception occur in topnav.getTopNavs ", error);
        data = { data: [], status: false };
    }
    return data;
};

const getLocaleOptions = async (data) => {
    try {
        DeepTrimAndCleanUp({ data, removeEmptyStringKeyFlag: true });
        const response = await API.getLocaleOptions(data)
            .then((res) => res)
            .catch((error) => {
                console.log("error", error);
                return (
                    error?.response || {
                        status: HttpStatus.NETWORK_CONNECT_TIMEOUT_ERROR,
                    }
                );
            });
        if (HttpStatus.SUCCESS.includes(response.status)) {
            const responseData = (response.data?.data || []).map(data => {
                data = {
                    id: data?.attributes?.langcode,
                    name: data?.attributes?.label,
                    direction: data?.attributes?.direction
                }
                return data;
            })
            data = { data: responseData, status: true };
        } else {
            data = { data: [], status: false };
        }
    } catch (error) {
        console.error("Exception occur in topnav.getLocaleOptions ", error);
        data = { data: [], status: false };
    }
    return data;
};

const getDashBorardTopNav = async (data) => {
    try {
        DeepTrimAndCleanUp({ data, removeEmptyStringKeyFlag: true });
        const response = await API.getDashBorardTopNav(data)
            .then((res) => res)
            .catch((error) => {
                console.log("error", error);
                return (
                    error?.response || {
                        status: HttpStatus.NETWORK_CONNECT_TIMEOUT_ERROR,
                    }
                );
            });
        if (HttpStatus.SUCCESS.includes(response.status)) {
            data = { ...data, ...response.data?.data, status: true };
        } else {
            data = { data: DASHBOARD_TOPNAV, status: false };
        }
    } catch (error) {
        console.error("Exception occur in topnav.getDashBorardTopNav ", error);
        data = { data: DASHBOARD_TOPNAV, status: false };
    }
    return data;
};


const exportedObject = {
    getTopNavs,
    getLocaleOptions,
    getDashBorardTopNav
};

export default exportedObject;
