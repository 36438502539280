import HttpStatus from "constants/HttpStatus";
import { DeepTrimAndCleanUp } from "helpers/DeepTrim";
import API from "./service";

const getBanners = async (data) => {
    try {
        DeepTrimAndCleanUp({ data, removeEmptyStringKeyFlag: true });
        const response = await API.getBanners(data)
            .then((res) => res)
            .catch((error) => {
                console.log("error", error);
                return (
                    error?.response || {
                        status: HttpStatus.NETWORK_CONNECT_TIMEOUT_ERROR,
                    }
                );
            });
        if (HttpStatus.SUCCESS.includes(response.status)) {
            data = { ...data, ...response.data, status: true };
        } else {
            data = { data: [], status: false };
        }
    } catch (error) {
        console.error("Exception occur in home.getBanners ", error);
        data = { data: [], status: false };
    }
    return data;
};

const getAboutUsDetails = async (data) => {
    try {
        DeepTrimAndCleanUp({ data, removeEmptyStringKeyFlag: true });
        const response = await API.getAboutUsDetails(data)
            .then((res) => res)
            .catch((error) => {
                console.log("error", error);
                return (
                    error?.response || {
                        status: HttpStatus.NETWORK_CONNECT_TIMEOUT_ERROR,
                    }
                );
            });
        if (HttpStatus.SUCCESS.includes(response.status)) {
            data = { ...data, ...response.data, status: true };
        } else {
            data = { data: [], status: false };
        }
    } catch (error) {
        console.error("Exception occur in home.getAboutUsDetails ", error);
        data = { data: [], status: false };
    }
    return data;
};

const getAboutUsSubIntroduction = async (data) => {
    try {
        DeepTrimAndCleanUp({ data, removeEmptyStringKeyFlag: true });
        const response = await API.getAboutUsSubIntroduction(data)
            .then((res) => res)
            .catch((error) => {
                console.log("error", error);
                return (
                    error?.response || {
                        status: HttpStatus.NETWORK_CONNECT_TIMEOUT_ERROR,
                    }
                );
            });
        if (HttpStatus.SUCCESS.includes(response.status)) {
            data = { ...data, ...response.data, status: true };
        } else {
            data = { data: [], included: [], status: false };
        }
    } catch (error) {
        console.error("Exception occur in home.getAboutUsDetails ", error);
        data = { data: [], included: [], status: false };
    }
    return data;
};

const getExprienceBornSmartDetails = async (data) => {
    try {
        DeepTrimAndCleanUp({ data, removeEmptyStringKeyFlag: true });
        const response = await API.getExprienceBornSmartDetails(data)
            .then((res) => res)
            .catch((error) => {
                console.log("error", error);
                return (
                    error?.response || {
                        status: HttpStatus.NETWORK_CONNECT_TIMEOUT_ERROR,
                    }
                );
            });
        if (HttpStatus.SUCCESS.includes(response.status)) {
            data = { ...data, ...response.data, status: true };
        } else {
            data = { data: [], status: false };
        }
    } catch (error) {
        console.error("Exception occur in home.getAboutUsDetails ", error);
        data = { data: [], status: false };
    }
    return data;
};

const getExprienceBornSmartVideo = async (data) => {
    try {
        DeepTrimAndCleanUp({ data, removeEmptyStringKeyFlag: true });
        const response = await API.getExprienceBornSmartVideo(data)
            .then((res) => res)
            .catch((error) => {
                console.log("error", error);
                return (
                    error?.response || {
                        status: HttpStatus.NETWORK_CONNECT_TIMEOUT_ERROR,
                    }
                );
            });
        if (HttpStatus.SUCCESS.includes(response.status)) {
            data = { ...data, ...response.data, status: true };
        } else {
            data = { data: [], status: false };
        }
    } catch (error) {
        console.error("Exception occur in home.getAboutUsDetails ", error);
        data = { data: [], status: false };
    }
    return data;
};

const getExprienceBornSmartCombine = async (data) => {
    try {
        DeepTrimAndCleanUp({ data, removeEmptyStringKeyFlag: true });
        const response = await API.getExprienceBornSmartCombine(data)
            .then((res) => res)
            .catch((error) => {
                console.log("error", error);
                return (
                    error?.response || {
                        status: HttpStatus.NETWORK_CONNECT_TIMEOUT_ERROR,
                    }
                );
            });
        if (HttpStatus.SUCCESS.includes(response.status)) {
            data = { ...data, ...response.data, status: true };
        } else {
            data = { data: [], status: false };
        }
    } catch (error) {
        console.error("Exception occur in home.getAboutUsDetails ", error);
        data = { data: [], status: false };
    }
    return data;
};


const getWhyChooseBornSmartDetails = async (data) => {
    try {
        DeepTrimAndCleanUp({ data, removeEmptyStringKeyFlag: true });
        const response = await API.getWhyChooseBornSmartDetails(data)
            .then((res) => res)
            .catch((error) => {
                console.log("error", error);
                return (
                    error?.response || {
                        status: HttpStatus.NETWORK_CONNECT_TIMEOUT_ERROR,
                    }
                );
            });
        if (HttpStatus.SUCCESS.includes(response.status)) {
            data = { ...data, ...response.data, status: true };
        } else {
            data = { data: [], status: false };
        }
    } catch (error) {
        console.error("Exception occur in home.getWhyChooseBornSmart ", error);
        data = { data: [], status: false };
    }
    return data;
};

const getParentingMadeEasyDetails = async (data) => {
    try {
        DeepTrimAndCleanUp({ data, removeEmptyStringKeyFlag: true });
        const response = await API.getParentingMadeEasyDetails(data)
            .then((res) => res)
            .catch((error) => {
                console.log("error", error);
                return (
                    error?.response || {
                        status: HttpStatus.NETWORK_CONNECT_TIMEOUT_ERROR,
                    }
                );
            });
        if (HttpStatus.SUCCESS.includes(response.status)) {
            data = { ...data, ...response.data, status: true };
        } else {
            data = { data: [], status: false };
        }
    } catch (error) {
        console.error("Exception occur in home.getWhyChooseBornSmart ", error);
        data = { data: [], status: false };
    }
    return data;
};

const getJoinBornSmartFreeDetails = async (data) => {
    try {
        DeepTrimAndCleanUp({ data, removeEmptyStringKeyFlag: true });
        const response = await API.getJoinBornSmartFreeDetails(data)
            .then((res) => res)
            .catch((error) => {
                console.log("error", error);
                return (
                    error?.response || {
                        status: HttpStatus.NETWORK_CONNECT_TIMEOUT_ERROR,
                    }
                );
            });
        if (HttpStatus.SUCCESS.includes(response.status)) {
            data = { ...data, ...response.data, status: true };
        } else {
            data = { data: [], status: false };
        }
    } catch (error) {
        console.error("Exception occur in home.getWhyChooseBornSmart ", error);
        data = { data: [], status: false };
    }
    return data;
};

const getJoinBornSmartPaidDetails = async (data) => {
    try {
        DeepTrimAndCleanUp({ data, removeEmptyStringKeyFlag: true });
        const response = await API.getJoinBornSmartPaidDetails(data)
            .then((res) => res)
            .catch((error) => {
                console.log("error", error);
                return (
                    error?.response || {
                        status: HttpStatus.NETWORK_CONNECT_TIMEOUT_ERROR,
                    }
                );
            });
        if (HttpStatus.SUCCESS.includes(response.status)) {
            data = { ...data, ...response.data, status: true };
        } else {
            data = { data: [], status: false };
        }
    } catch (error) {
        console.error("Exception occur in home.getWhyChooseBornSmart ", error);
        data = { data: [], status: false };
    }
    return data;
};

const getKnowYourChildDetails = async (data) => {
    try {
        DeepTrimAndCleanUp({ data, removeEmptyStringKeyFlag: true });
        const response = await API.getKnowYourChildDetails(data)
            .then((res) => res)
            .catch((error) => {
                console.log("error", error);
                return (
                    error?.response || {
                        status: HttpStatus.NETWORK_CONNECT_TIMEOUT_ERROR,
                    }
                );
            });
        if (HttpStatus.SUCCESS.includes(response.status)) {
            data = { ...data, ...response.data, status: true };
        } else {
            data = { data: [], included: [], status: false };
        }
    } catch (error) {
        console.error("Exception occur in home.getWhyChooseBornSmart ", error);
        data = { data: [], includede: [], status: false };
    }
    return data;
};

const getVideosDetails = async (data) => {
    try {
        DeepTrimAndCleanUp({ data, removeEmptyStringKeyFlag: true });
        const response = await API.getVideosDetails(data)
            .then((res) => res)
            .catch((error) => {
                console.log("error", error);
                return (
                    error?.response || {
                        status: HttpStatus.NETWORK_CONNECT_TIMEOUT_ERROR,
                    }
                );
            });
        if (HttpStatus.SUCCESS.includes(response.status)) {
            data = { ...data, ...response.data, status: true };
        } else {
            data = { data: [], status: false };
        }
    } catch (error) {
        console.error("Exception occur in home.getWhyChooseBornSmart ", error);
        data = { data: [], status: false };
    }
    return data;
};

const getNewsLetterDetails = async (data) => {
    try {
        DeepTrimAndCleanUp({ data, removeEmptyStringKeyFlag: true });
        const response = await API.getNewsLetterDetails(data)
            .then((res) => res)
            .catch((error) => {
                console.log("error", error);
                return (
                    error?.response || {
                        status: HttpStatus.NETWORK_CONNECT_TIMEOUT_ERROR,
                    }
                );
            });
        if (HttpStatus.SUCCESS.includes(response.status)) {
            data = { ...data, ...response.data, status: true };
        } else {
            data = { data: [], included: [], status: false };
        }
    } catch (error) {
        console.error("Exception occur in home.getNewsLetterDetails ", error);
        data = { data: [], included: [], status: false };
    }
    return data;
};

const getFaqDetails = async (data) => {
    try {
        DeepTrimAndCleanUp({ data, removeEmptyStringKeyFlag: true });
        const response = await API.getFaqDetails(data)
            .then((res) => res)
            .catch((error) => {
                console.log("error", error);
                return (
                    error?.response || {
                        status: HttpStatus.NETWORK_CONNECT_TIMEOUT_ERROR,
                    }
                );
            });
        if (HttpStatus.SUCCESS.includes(response.status)) {
            data = { ...data, ...response.data, status: true };
        } else {
            data = { data: [], status: false };
        }
    } catch (error) {
        console.error("Exception occur in home.getFaqDetails ", error);
        data = { data: [], status: false };
    }
    return data;
};

const getBabyDevelopmentMonth = async (data) => {
    try {
        DeepTrimAndCleanUp({ data, removeEmptyStringKeyFlag: true });
        const response = await API.getBabyDevelopmentMonth(data)
            .then((res) => res)
            .catch((error) => {
                console.log("error", error);
                return (
                    error?.response || {
                        status: HttpStatus.NETWORK_CONNECT_TIMEOUT_ERROR,
                    }
                );
            });
        if (HttpStatus.SUCCESS.includes(response.status)) {
            data = { ...data, ...response.data, status: true };
        } else {
            data = { data: [], status: false };
        }
    } catch (error) {
        console.error("Exception occur in home.getFaqDetails ", error);
        data = { data: [], status: false };
    }
    return data;
};

const getBabyBodyPartDetails = async (data) => {
    try {
        DeepTrimAndCleanUp({ data, removeEmptyStringKeyFlag: true });
        const response = await API.getBabyBodyPartDetails(data)
            .then((res) => res)
            .catch((error) => {
                console.log("error", error);
                return (
                    error?.response || {
                        status: HttpStatus.NETWORK_CONNECT_TIMEOUT_ERROR,
                    }
                );
            });
        if (HttpStatus.SUCCESS.includes(response.status)) {
            data = { ...data, ...response.data, status: true };
        } else {
            data = { data: [], status: false };
        }
    } catch (error) {
        console.error("Exception occur in home.getFaqDetails ", error);
        data = { data: [], status: false };
    }
    return data;
};

const getParentingMadeEasy = async (data) => {
    try {
        DeepTrimAndCleanUp({ data, removeEmptyStringKeyFlag: true });
        const response = await API.getParentingMadeEasy(data)
            .then((res) => res)
            .catch((error) => {
                console.log("error", error);
                return (
                    error?.response || {
                        status: HttpStatus.NETWORK_CONNECT_TIMEOUT_ERROR,
                    }
                );
            });
        if (HttpStatus.SUCCESS.includes(response.status)) {
            data = { ...data, ...response.data, status: true };
        } else {
            data = { data: [], status: false };
        }
    } catch (error) {
        console.error("Exception occur in home.getParentingMadeEasy ", error);
        data = { data: [], status: false };
    }
    return data;
}

const exportedObject = {
    getBanners,
    getAboutUsDetails,
    getAboutUsSubIntroduction,
    getExprienceBornSmartDetails,
    getExprienceBornSmartVideo,
    getExprienceBornSmartCombine,
    getWhyChooseBornSmartDetails,
    getParentingMadeEasyDetails,
    getJoinBornSmartFreeDetails,
    getJoinBornSmartPaidDetails,
    getKnowYourChildDetails,
    getVideosDetails,
    getNewsLetterDetails,
    getFaqDetails,
    getBabyDevelopmentMonth,
    getBabyBodyPartDetails,
    getParentingMadeEasy
};

export default exportedObject;
