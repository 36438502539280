import axios from "axios";

const BASE_API = process.env.REACT_APP_BASE_API;

class FaqService {
    getFaqMainDetails = async (data) => {
        return axios.get(`${BASE_API}/taxonomy_term/faq_type`, {
            params: data,
        });
    };
    getFagData = async (data) => {
        return axios.get(`${BASE_API}/node/faq`, {
            params: data,
        });
    };
}

 export default new FaqService();